import * as React from "react"
import Layout from "../components/Layout";
import PageWrapper from "../components/PageWrapper";
import {createNavItems} from "../utils/nav-items-util";
import {graphql, PageProps} from "gatsby";
import SEO from "../components/seo";

type Props = {
  prismicAbout: {
    uid: string
    url: string
    data: {
      title: {
        html: string
        text: string
      }
      description: {
        html: string
        text: string
      }
      cover: {
        url: string
        alt: string
      }
      body: {
        html: string
      }
    }
  }
}

const AboutPage: React.FC<PageProps<Props>> = ({data: {prismicAbout}, location}) => {
  const about = prismicAbout.data
  return (
    <Layout navItems={createNavItems({current: "about"})}>
      <SEO
        title={about.title.text}
        description={about.description.text}
        pathname={location.pathname}
        image={about.cover?.url}
      />
      <PageWrapper className={"mt-16 mb-16"}>
        <div className="max-w-screen-sm items-center mx-auto prose">
          <div className="text-center" dangerouslySetInnerHTML={{__html: about.title.html}}/>
          <div className="text-center" dangerouslySetInnerHTML={{__html: about.description.html}}/>
          {about.cover.url && <img className="mx-auto" src={about.cover.url} alt={(about.cover.alt)}/>}
          <div dangerouslySetInnerHTML={{__html: about.body.html}}/>
        </div>
      </PageWrapper>
    </Layout>
  )
};

export default AboutPage

export const query = graphql`
  query AboutPage {
    prismicAbout {
      uid
      url
      data {
        title {
          html
          text
        }
        description {
          html
          text
        }
        cover {
          url
          alt
        }
        body {
          html
        }
      }
    }
  }
`;
